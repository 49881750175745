
.Photo{
    background-image: url('../img/logo.png');
}
.project1{
    background-image: url('../img/mmeink.png');
    
}
.project2{
    background-image: url('../img/emaxinteractive.png');

}
.project3{
    background-image: url('../img/infusopops.png');

}
.project4{
    background-image: url('../img/441vintage.png');

}
.project5{
    background-image: url('../img/annecole.png');

}
.project6{
    background-image: url('../img/eastover.png');

}
.misc1{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+one');

}
.misc2{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+two');

}
.misc3{
    background-image: url('https://via.placeholder.com/400x600.png?text=Miscellaneous+three');

}